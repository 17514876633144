import { createContext, useEffect, useState } from "react";
import { ProductsAPI } from "../utils/ProductsApi";
// import ProductsData from "../../src/utils/Data/ProductsData.json";

export const ProductsContext = createContext({
  products: [],
  getMyProduts: () => null,
});

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getMyProduts();
  }, []);

  const getMyProduts = async () => {
    ProductsAPI()
      .getProduts()
      .then((data) => {
        setProducts(data);
      });
  };

  const reCountProductsQuantity = (productToRemove) => {
    //console.log(productToRemove.id);
    const existingItem = products.find(
      (item) => item.id === productToRemove.id
    );

    existingItem.quantity = 0;
  };

  const reduceQuantity = (productToDecrease) => {
    const existingItem = products.find(
      (item) => item.id === productToDecrease.id
    );

    existingItem.quantity = existingItem.quantity - 1;
  };

  const increaseQuantity = (productToIncrease) => {
    const existingItem = products.find(
      (item) => item.id === productToIncrease.id
    );

    existingItem.quantity = existingItem.quantity + 1;
  };

  const value = {
    products,
    getMyProduts,
    reCountProductsQuantity,
    increaseQuantity,
    reduceQuantity,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
