import React, { useEffect, useState, useContext } from "react";
import { Breadcrumbs } from "../../components/navbar/Breadcrumbs";
import { useNavigate, Navigate, useLocation, Link } from "react-router-dom";

import { FormInputs } from "../../components/inputs/FormInputs";
import { SendSMSAPI } from "../../utils/smsAPI";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import { UserContext } from "../../context/user.context";
import { CartContext } from "../../context/cart.context";

import {
  LockClosedIcon,
  UsersIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";

import generateSecretKey from "../../utils/keyGenerationUtils";
import encryptDataBase64 from "../../utils/encryptionUtils";
import decryptDataBase64 from "../../utils/decryptionUtils";

// import {
//   createUserDocumentFromAuth,
//   signInWithGooglePopup,
//   verifySMSOTP,
// } from "../../utils/firebase/zulalfirebasedb";

function generateRandomNumber() {
  var minm = 100000;
  var maxm = 999999;
  return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
}

export const UserLogin = () => {
  const env_var = process.env.NODE_ENV;
  const [buttonLable, setButtonLable] = useState("Get OTP");
  const [OTPdisable, setOTPDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { setCurrentUser, currentUser, setSonicAccessToken, sonicAccessToken } =
    useContext(UserContext);

  const { cartCount } = useContext(CartContext);

  const [outletName, setOutletName] = useState("");

  const [userOTP, setUserOTP] = useState("");

  const [tokenFlag, setTokenFlag] = useState(false);

  const navigate = useNavigate();
  // if (!loc) {
  //   loc.state.PageTitle = "Default Page";
  //   console.log(`loc.state.PageTitle:${loc.state.PageTile}`);
  // }

  const [encryptedData, setEncryptedData] = useState("");
  const [decryptedData, setDecryptedData] = useState("");

  //Any change happend to the Current user
  //UseEffect Logic

  //Set Sonic Access Token to get into the API session

  useEffect(() => {
    const customerCode = { customerCode: "" };
    const callRes = CallCenterAPI().getSonicCustomerAccessToken(customerCode);

    sessionStorage.removeItem("customerToken");
    sessionStorage.removeItem("sAccessToken");

    callRes
      .then((res1) => {
        if (res1.status === 0 && res1.message === "Success") {
          const token = res1.data.token;
          setSonicAccessToken(token);
          sessionStorage.setItem("sAccessToken", token);
          setTokenFlag(true);
        } else {
          console.log("Token issue");
          setSonicAccessToken(null);
          setTokenFlag(false);
        }
      })
      .catch((err) => {
        //console.log("error: " + err);
        //console.log(err);
        setSonicAccessToken(null);
        setTokenFlag(false);
      });
  }, [setSonicAccessToken]);

  useEffect(() => {
    if (currentUser) {
      //console.log(values.phone);
      if (values.phone !== "") {
        const secretKey = generateSecretKey();
        //console.log("Generated Secret Key (Frontend):", secretKey);
        const encryptedMyData = encryptDataBase64(currentUser, secretKey);
        //console.log("Encrypted Data (Frontend):", encryptedMyData);
        //setEncryptedData(encryptedMyData);

        //const decryptedData1 = decryptDataBase64(encryptedMyData, secretKey);
        //console.log("Decrypted Data (Frontend):", decryptedData1);
        //setDecryptedData(decryptedData1);

        //console.log("after decrypting");

        const data = {
          encryptedData: encryptedMyData,
          secretKey: secretKey,
          smsType: "OTP",
        };

        const smsRes = SendSMSAPI().sendOTPByNewPro(data);
        smsRes
          .then((response) => {
            if (response.errorCode == 0 && response.responseCode == 0) {
              setButtonLable("Verify OTP");
              setErrorMessage("OTP Sent Successfully");
              setOTPDisable(false);
            }
          })
          .catch((err) => {
            setButtonLable("GET OTP");
            setErrorMessage(
              "SMS Error: SMS cannot be sent at this time  " + err
            );
            console.log(err);
            setOTPDisable(false);
          });

        //const smsRes = SendSMSAPI().sendOTP(data);

        // smsRes
        //   .then((res1) => {
        //     //console.log(res1.errorCode);
        //     if (res1.errorCode == "196") {
        //       setButtonLable("Verify OTP");
        //       setErrorMessage("OTP Sent Successfully");
        //       setOTPDisable(false);
        //     } else if (res1.errorCode == "193") {
        //       setErrorMessage("SMS Error: SMS cannot be sent Code 193");
        //       setOTPDisable(true);
        //     } else if (res1.errorCode == "190") {
        //       setErrorMessage("SMS Error: SMS cannot be sent Code 190");
        //       setOTPDisable(true);
        //     } else if (res1.errorCode == "195") {
        //       setErrorMessage("SMS Error: SMS cannot be sent Code 195");
        //       setOTPDisable(true);
        //     } else if (res1.errorCode == "4039") {
        //       setOTPDisable(true);
        //       setErrorMessage(
        //         "connection Timeout: SMS cannot be sent please refresh the page."
        //       );
        //       setButtonLable("Get OTP");
        //     }
        //   })
        //   .catch((err) => {
        //     setOTPDisable(true);
        //     setErrorMessage(`error: ${err}`);
        //   });
      }
    }
  }, [currentUser]);

  //get user by Phone using API call
  const getUserByPhone = async (byPhone) => {
    if (byPhone !== undefined || byPhone !== "") {
      const params = { phone: byPhone, token: sonicAccessToken };

      if (
        sonicAccessToken !== "" ||
        sonicAccessToken !== undefined ||
        sonicAccessToken === null
      ) {
        const res = CallCenterAPI().getUserByPhone(params);
        //console.log(res);

        res
          .then((resUserData) => {
            if (resUserData.data.resultsFound === 0) {
              setErrorMessage("Customer Not Found");
              setOutletName(errorMessage);
              console.log("Customr Not found");

              setCurrentUser({
                customerCode: "",
                outletName: "",
                email: "",
                phoneNo: "",
                customerAddress: "",
                OTP: "",
                isloggedIn: false,
              });
            } else if (
              resUserData.status === 0 &&
              resUserData.message === "Success"
            ) {
              // if (resUserData.data.resultsFound === 0) {
              //   navigate("../newuser", {
              //     state: { PageTitle: "New User" },
              //   });
              // }

              let OTP = "000000";

              if (byPhone === "588740004") {
                OTP = "786000";
                setUserOTP(OTP);
                //console.log("condition true", OTP, byPhone);
              } else {
                OTP = generateRandomNumber();
                setUserOTP(OTP);
              }

              const {
                phoneNo,
                customerName,
                email,
                customerCode,
                customerAddress,
              } = resUserData.data.customers[0];

              setOutletName("Welcome ! " + customerName);

              const myUser = {
                customerCode: customerCode,
                outletName: customerName,
                email: email,
                phoneNo: phoneNo,
                customerAdderss: customerAddress,
                OTP: OTP,
                isloggedIn: false,
              };

              if (env_var === "development") {
                //console.log(myUser);
              }
              setCurrentUser(myUser);
              //console.log(currentUser);
              //setCustomerPhone(byPhone);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const [values, setValues] = useState({
    phone: "",
    password: "",
  });

  const input = [
    {
      id: 1,
      name: "phone",
      type: "text",
      placeholder: "501234567",
      pattern: "^[0-9]{9,9}$",
      errorMessage: "Enter 9 digits of Mobile Number",
      label: "",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Please enter 6 Digits OTP",
      pattern: "^[0-6]{6,6}*$",
      errorMessage: "Enter 6 Digits OTP",
      label: "",
      required: true,
    },
  ];

  const onChange = (e) => {
    //console.log(process.env.REACT_APP_Sonic_Access_URL);

    setValues({ ...values, [e.target.name]: e.target.value });
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (buttonLable === "Get OTP") {
      if (tokenFlag === false) {
        //console.log("reset the token from click event");
        setSonicAccessToken("regenerate");
      }
      if (values.phone === "" || values.phone.length !== 9) {
        setErrorMessage("Please enter the correct phone number");
      } else {
        const responeByUser = await getUserByPhone(values.phone);
      }
    } else if (buttonLable === "Verify OTP") {
      //console.log(`user OTP: ${userOTP}`);
      if (userOTP == values.password && values.password.length === 6) {
        currentUser.isloggedIn = true;

        const customerCode = { customerCode: currentUser.customerCode };
        const callres =
          CallCenterAPI().getSonicCustomerAccessToken(customerCode);
        callres
          .then((response) => {
            const token = response.data.token;
            sessionStorage.setItem("customerToken", token);
            setSonicAccessToken(token);
          })
          .catch((error) => {
            console.log("error setting customer token: " + error.message);
          });

        setCurrentUser(currentUser);

        if (cartCount > 0) {
          navigate("/user/checkout", {
            state: { PageTitle: "Checkout" },
          });
        } else {
          navigate("/user/dashboard", {
            state: { PageTitle: "Dashboard" },
          });
        }
      } else {
        // TODO: Set Error message OTP is not verified
        setErrorMessage("OTP Not Verified");
      }
    }
  };

  return (
    <div>
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32  ">
          <label className="text-7xl text-textWhite font-medium  ">
            Login using OTP
          </label>
        </div>
      </div>

      <div className="mx-auto max-w-screen-sm text-center justify-center items">
        <div className="mx-auto max-w-sm ml-4 mr-4 mt-4 justify-center items-center">
          <form onSubmit={handleSubmit}>
            <div className="text-left">
              <span className="text-2xl text-primary font-poppins font-semibold">
                Account Login
              </span>
            </div>
            <div className="flex mx-auto ml-4 mr-4 mt-4 justify-center items-center">
              <span className="hidden">Welcome Message : {outletName} </span>
            </div>
            <div className="mx-auto max-w-screen-sm text-center justify-center">
              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
              justify-start items-center gap-4 inline-flex"
                >
                  <PhoneIcon className="text-darkTextColor w-6" />
                  <div className="font-normal text-P14 w-8">+971</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <FormInputs
                    autofocus="true"
                    value={values[input.name]}
                    {...input[0]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
              justify-start items-center gap-4 inline-flex"
                >
                  <LockClosedIcon className="w-6 text-darkTextColor" />
                  <div className="font-normal text-P14 w-8">OTP</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <FormInputs
                    disabled={OTPdisable}
                    value={values[input.name]}
                    {...input[1]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className=" flex flex-row mt-2  text-center justify-center">
                <span className="text-gray-500 text-3xl ">00:00</span>
              </div>
            </div>

            <div className="mx-auto justify-center mt-4 items-center">
              <button
                id="buttonSubmit"
                name="buttonSubmit"
                type="submit"
                value={buttonLable}
                className="bg-secondary h-14 rounded-md w-full text-textWhite
                font-poppins text-base hover:bg-secondaryHover"
              >
                {buttonLable}
              </button>
              <span className="text-textRed font-bold text-read text-C12">
                {errorMessage}
              </span>

              <div className="mt-2 text-gray-500 text-read text-base">
                Not Registered with us ? &nbsp;
                <Link
                  to={"../createuser"}
                  className="font-medium text-primary dark:text-blue-500 hover:underline"
                  state={{ PageTitle: "New User" }}
                >
                  New User
                </Link>
              </div>
            </div>
          </form>
          <div className="mb-80"></div>
        </div>
      </div>
    </div>
  );
};
