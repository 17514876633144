import axios from "axios";
import { Products_API_URL } from "./config";

export const ProductsAPI = () => {
  const env_var = process.env.NODE_ENV;

  return {
    getProduts: (req, res) =>
      axios
        .get(Products_API_URL)
        .then((response) => {
          res = response.data;
          return res;
        })
        .catch((err) => {
          if (env_var === "production") {
            console.log("Get Products Error: ");
          } else {
            console.log("Get Products Error: " + err.message);
          }
        }),
  };
};
