export const encryptData = "https://www.zulal.ae:5001/api/encrypt";
export const SMS_BASE_URL = "https://www.zulal.ae:5001/api/v1/sms/sendSMS";
export const SMS_NEW_URL =
  "https://www.zulal.ae:5001/api/v1/sms/sendSMSUsingQuery";

export const Customer_API = "https://www.zulal.ae:5001/";

export const Sonic_Access_URL = "https://www.zulal.ae:5001/api/v1/getSonicAuth";

export const Sonic_SearchCustomer_URL =
  "https://www.zulal.ae:5001/api/v1/searchCustomer";

export const Sonic_CustomerDetails_URL =
  "https://www.zulal.ae:5001/api/v1/getCustomerDetails";

export const Sonic_GetCountries_URL =
  "https://www.zulal.ae:5001/api/v1/getCountries";
export const Sonic_GetStates_URL = "https://www.zulal.ae:5001/api/v1/getStates";
export const Sonic_GetCities_URL = "https://www.zulal.ae:5001/api/v1/getCities";
export const Sonic_GetAreas_URL = "https://www.zulal.ae:5001/api/v1/getAreas";

export const Sonic_UpdateCustomerDetails_URL =
  "https://www.zulal.ae:5001/api/v1/customer/updateCustomerDetails";

export const Sonic_UpdateCustomerInfo_URL =
  "https://www.zulal.ae:5001/api/v1/customer/updateCustomerInfo";

export const Sonic_CreateNewCustomer_URL =
  "https://www.zulal.ae:5001/api/v1/createNewCustomer";

export const Sonic_GetCustomerICash_URL =
  "https://www.zulal.ae:5001/api/v1/customer/getICashDetails";

export const Sonic_GetCustomerPaymentsHistory =
  "https://www.zulal.ae:5001/api/v1/customer/getCustomerLast5Invoices";

export const Sonic_GetCustomerLast5Orders =
  "https://www.zulal.ae:5001/api/v1/customer/getCustomerLast5Orders";

export const Sonic_GetCustomerOrdersHistory =
  "https://www.zulal.ae:5001/api/v1/customer/GetCustomerOrdersHistory";

export const Sonic_Save_ICashAdjustment =
  "https://www.zulal.ae:5001/api/v1/ICash/ICashAdjustment";

export const Sonic_SendOrder_URL =
  "https://www.zulal.ae:5001/api/v1/customer/sendSonicOrder";

export const NGen_AccessToken_API_URL =
  "https://www.zulal.ae:5001/api/NI/getAccessToken";

export const NGen_AccessToken_API_URL1 =
  "https://www.zulal.ae:5001/api/v1/genAuth";

export const NGen_ORDERS_API_URL =
  "https://www.zulal.ae:5001/api/NI/createOrderNew";
//$(`zulal_Outlet`)/orders

export const NGen_GetOrder_API_URL =
  "https://www.zulal.ae:5001/api/NI/getOrder";

export const Sonic_ICashTopUp_URL =
  "https://www.zulal.ae:5001/api/v1/customer/ICashTopUp";

export const SEWA_SendEmail_URL = "https://www.zulal.ae:5001/api/sendEmail";

export const Products_API_URL =
  "https://www.zulal.ae:5001/api/v1/products/getProducts";

export const NewOrderConfirmation_API_URL =
  "https://www.zulal.ae:5001/api/v1/orders/newOrderConfirmation";
