import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { useContext } from "react";
import { CartContext } from "../../context/cart.context";
import {
  Link,
  NavLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { UilTrashAlt } from "@iconscout/react-unicons";
import { ProductsContext } from "../../context/products.context";

export default function CartDropDownComponent({ showCart, onClose }) {
  //  const [open, setOpen] = useState(true);

  const {
    cartItems,
    clearItemfromCart,
    cartCount,
    cartTotal,
    isCartOpen,
    setIsCartOpen,
  } = useContext(CartContext);

  const { reCountProductsQuantity } = useContext(ProductsContext);

  const [data, setData] = useState({
    PageTitle: "Checkout",
    prevURL: "/user/checkout",
  });

  function checkItemtoRemove(product) {
    // console.log(product.id);
    // console.log(product);

    reCountProductsQuantity(product);
    clearItemfromCart(product);
  }

  function addValues(val1, val2) {
    const total = parseFloat(val1) + parseFloat(val2);
    return total.toFixed(2);
  }

  function multipleOfValues(val1, val2) {
    const total = parseFloat(val1) * parseFloat(val2);
    return total.toFixed(2);
  }

  return (
    <Transition.Root show={showCart} as={Fragment}>
      <Dialog as="div" className="relative z-[21]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden font-poppins">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Shopping cart
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={onClose}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          {cartCount > 0 ? (
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-cool-gray-300"
                            >
                              {cartItems.map((product) => (
                                <li key={product.id} className="flex py-6">
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-cool-gray-300">
                                    <img
                                      src={product.imageSrc}
                                      alt={product.imageAlt}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-C12 font-medium text-cool-gray-600">
                                        <h3>
                                          <a href={product.href}>
                                            {product.description}
                                          </a>
                                        </h3>
                                        <p className="ml-4">
                                          {multipleOfValues(
                                            product.price,
                                            product.quantity
                                          )}{" "}
                                          AED
                                        </p>
                                      </div>
                                      <p className="mt-1 text-C12 text-cool-gray-500">
                                        {product.name}
                                      </p>
                                    </div>
                                    <div className="flex flex-1 items-end justify-between text-sm">
                                      <p className="text-cool-gray-600">
                                        Qty {product.quantity}
                                      </p>

                                      <div className="flex">
                                        <button
                                          onClick={(e) =>
                                            checkItemtoRemove(product)
                                          }
                                          type="button"
                                          className="font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                          <UilTrashAlt className="text-cool-gray-600 hover:text-cool-gray-400" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-cool-gray-300"
                            >
                              <span className="text-2xl text-center items-center justify-center">
                                Cart is empty
                              </span>
                              <img src="../../images/emptycart.jpg" alt="" />
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-cool-gray-300 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Subtotal</p>
                        <p>{cartTotal.toFixed(2)} AED</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Subtotal is excluding taxes.
                      </p>
                      <div className="mt-6">
                        {cartCount > 0 ? (
                          <Link
                            to="/user/checkout"
                            state={{ PageTitle: "Checkout" }}
                            onClick={onClose}
                            className="flex items-center justify-center rounded-md 
                            border border-transparent 
                            bg-secondary hover:bg-secondaryHover
                            px-6 py-3 text-base font-medium text-textWhite hover:text-cool-gray-700 shadow-sm hover:bg-indigo-700"
                          >
                            Checkout
                            <span aria-hidden="true"> &rarr;</span>
                          </Link>
                        ) : (
                          <Link
                            className="flex  items-center justify-center rounded-md border 
                              border-transparent bg-cool-gray-300 cursor-pointer pointer-events-none px-6 py-3 
                              text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                          >
                            Checkout
                          </Link>
                        )}
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or{" "}
                          <Link
                            to="/productsrange"
                            type="button"
                            state={{ PageTitle: "Zulal Store" }}
                            className="font-medium text-primary hover:text-primaryHover"
                            onClick={onClose}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
