import { DashboardInfo } from "./DashboardInfo";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/user.context";
import { CallCenterAPI } from "../../utils/callcenterAPI";

import { UilBill, UilShoppingCart, UilSync } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

import ScaleLoader from "react-spinners/ScaleLoader";

export const Dashboard = () => {
  const { currentUser, sonicAccessToken, customerDetails, setCustomerDetails } =
    useContext(UserContext);

  const env_var = process.env.NODE_ENV;

  // set Icash Balance
  const [iCashBalance, setICashBalance] = useState(-1);

  //get and set Invoice Amount and Last 5 Invoices
  const [lastInvoiceAmount, setLastInvoiceAmount] = useState(-1);
  const [last5Payments, setLast5Payments] = useState({});

  //get and set Order Amount and Last 5 Orders
  const [last5Orders, setLast5Orders] = useState({});
  const [lastOrderAmount, setLastOrderAmount] = useState(-1);
  const [loading, setLoading] = useState(false);

  const [messageCenter, setMessageCenter] = useState("");

  //console.log(`sonicAccessToken from dashboard: ${sonicAccessToken}`);

  useEffect(() => {
    if (sonicAccessToken !== null) {
      const params = { customerToken: sonicAccessToken };
      setLoading(true);
      getCustomerDetails(params);
      getCustomerICash(params);
      getCustomerOrdersHistory(params);
      getCustomerPaymentsHistory(params);
      setLoading(false);
    }
  }, [sonicAccessToken]);

  function RefreshData() {
    const params = { customerToken: sonicAccessToken };
    setLoading(true);
    getCustomerDetails(params);
    getCustomerICash(params);
    getCustomerOrdersHistory(params);
    getCustomerPaymentsHistory(params);
    setLoading(false);
  }

  const getCustomerDetails = async (params) => {
    //console.log(params.customerToken);
    if (Object.keys(params).length !== 0) {
      const CustomerDetails = CallCenterAPI().getCustomerDetails(params);
      //console.log(customerDetails);
      CustomerDetails.then((detailres) => {
        // console.log(detailres.data.splGroupCode);
        //console.log(detailres.data);

        if (detailres !== null) {
          setCustomerDetails({
            name: detailres.data.name,
            email: detailres.data.email,
            source: "web",
            DOB: "1990-01-01",
            AreaId: detailres.data.areaId,
            landmark: detailres.data.landmark,
            bldgName: detailres.data.bldgName,
            countryID: detailres.data.countryID,
            stateID: detailres.data.stateID,
            cityID: detailres.data.cityID,
            flatNumber: detailres.data.flatNumber,
            postcode: "000000",
            Currency: "AED",
            splGroupCode: detailres.data.splGroupCode,
            addresses: [
              {
                address: detailres.data.addresses[0].address,
                is_Default: true,
              },
            ],
            numbers: [
              {
                number: detailres.data.numbers[0].number,
                is_Default: true,
                CountryId: 1,
              },
            ],
          });

          if (env_var === "development") {
            console.log("Development mode enabled");
            console.log(customerDetails);
          }
        }
      }).catch((error) => {
        if (env_var === "development") {
          console.log(error.message);
        } else if (env_var === "production") {
          console.log("Dashboard:ERROR - Unknown Error ");
        }
      });
    }
  };

  const getCustomerICash = async (params) => {
    const customerICash = CallCenterAPI().getCustomerICash(params);

    //console.log(customerICash);

    customerICash
      .then((response) => {
        //console.log(response.data.balance);

        const balance = response.data.balance;
        //console.log(balance);
        setICashBalance(balance);
      })
      .catch((error) => {
        //console.log(error.message);
      });
  };

  const getCustomerPaymentsHistory = async (params) => {
    if (Object.keys(params).length !== 0) {
      const customerPaymentsHistory =
        CallCenterAPI().getCustomerPaymentsHistory(params);

      customerPaymentsHistory
        .then((response) => {
          if (response.status == -1) {
            //console.log("NO Record Found");
            setLastInvoiceAmount(0);
            setLast5Payments(response);
          } else if (response.status == 0) {
            // console.log(response.data.paymentList[0].paidAmt);
            setLastInvoiceAmount(response.data.paymentList[0].paidAmt);
            setLast5Payments(response);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const getCustomerOrdersHistory = (params) => {
    if (Object.keys(params).length !== 0) {
      const customerOrdersHistory =
        CallCenterAPI().getCustomerLast5Orders(params);

      customerOrdersHistory
        .then((response) => {
          if (response.data !== null) {
            setLastOrderAmount(response.data.orderList[0].netTotal);
            setLast5Orders(response);
          } else {
            setLastOrderAmount(0);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setLastOrderAmount(0);
        });
    }
  };

  function PaymentList({ payments }) {
    if (Object.keys(payments).length !== 0) {
      return (
        <div>
          <ul
            role="list"
            className="divide-y divide-gray-200 dark:divide-gray-700"
          >
            {payments &&
              payments.data.paymentList.map((item, index) => (
                <li className="py-3 sm:py-4" key={index}>
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <UilBill className="w-8 h-8 text-primaryHover " />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {item.invoiceNumber}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {item.paymentType}
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {parseFloat(item.paidAmt).toFixed(2)} <br />
                      AED
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      );
    }
  }

  function OrdersList({ orderList }) {
    if (Object.keys(orderList).length !== 0) {
      return (
        <div>
          <ul
            role="list"
            className="divide-y divide-gray-200 dark:divide-gray-700"
          >
            {orderList &&
              orderList.data.orderList.map((item, index) => (
                <li className="py-3 sm:py-4" key={index}>
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <UilShoppingCart className="w-8 h-8 text-primaryHover " />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {item.orderNumber}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        Items Count : {item.orderItems.length}
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {parseFloat(item.netTotal).toFixed(2)} <br />
                      AED
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="flex mx-auto max-w-screen-lg justify-start items-start mb-48">
        <div className="ml-2 mr-2 mt-4 gap-y-5 w-10/12 h-fit px-6 max-w-full rounded-md font-poppins justify-start items-start">
          {/* */}
          <div>
            <ScaleLoader
              color="#36d7b7"
              height={40}
              loading={loading}
              radius={2}
              width={4}
            />
          </div>
          <div>
            <div className="flex flex-row gap-5 items-center">
              <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
                User Dashboard
                <p className="text-base text-darkTextColor font-poppins font-normal">
                  Please click on refresh button if data not sync
                </p>
              </div>

              <button
                className="flex rounded-md bg-secondary h-10 w-10 items-center justify-center text-center"
                onClick={RefreshData}
              >
                <UilSync className="w-6 text-textWhite" />
              </button>
            </div>
            <DashboardInfo
              iCashBalance={iCashBalance}
              lastInvoiceAmount={lastInvoiceAmount}
              lastOrderAmount={lastOrderAmount}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 mt-10 gap-4">
            {/* Last 5-6 invoices */}
            <div className="hidden  w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Last 5 Invoices
                </h5>
                <Link
                  to="../invoices"
                  state={{ PageTitle: "Invoice(s) History" }}
                  className="text-navarbarText hover:text-primaryHover flex flex-row gap-2 text-base font-poppins font-medium"
                >
                  View All
                </Link>
              </div>

              <div>
                {last5Payments.status === 0 ? (
                  <PaymentList payments={last5Payments} />
                ) : (
                  <p>No Record found</p>
                )}
              </div>
            </div>

            {/* Last 5-6 Orders */}
            <div className="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
              <div className="flex items-center justify-between mb-4">
                <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                  Last 5 Orders
                </h5>
                <Link
                  to="../orders"
                  state={{ PageTitle: "Order(s) History" }}
                  className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  View all
                </Link>
              </div>
              <div>
                {last5Orders.status === 0 ? (
                  <OrdersList orderList={last5Orders} />
                ) : (
                  <p>No Record Found!</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
