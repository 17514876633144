import { useState, React, Fragment } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { UilWallet } from "@iconscout/react-unicons";

import { PhoneArrowUpRightIcon, WalletIcon } from "@heroicons/react/24/solid";
import UserMenu from "./UserMenu";
import { Outlet, Link } from "react-router-dom";
import CartIconComponent from "../../spa/shop/CartIconComponent";
import CartDropDownComponent from "../../spa/shop/CartDropDownComponent";

import { useContext } from "react";
import { UserContext } from "../../context/user.context";
import { CartContext } from "../../context/cart.context";
import { UilWhatsapp } from "@iconscout/react-unicons";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About Zulal", href: "/About" },
  { name: "Zulal Store", href: "/productsrange" },
  { name: "News & Media", href: "/NewsMedia" },
  { name: "Contact Us", href: "/ContactUs" },
];

export default function NavbarUI({ logo }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { isCartOpen, setIsCartOpen } = useContext(CartContext);
  const [showCart, setShowCart] = useState(false);

  return (
    <Fragment>
      <header className="sticky top-0 z-[20] bg-textWhite font-poppins">
        <div className="sticky top-0 mx-auto max-w-screen-xl">
          <header className="bg-textWhite text-primary dark:bg-darkBase dark:text-secondary ">
            <nav
              className="mx-auto flex relative items-center justify-between gap-x-6 p-6 lg:px-20 "
              aria-label="Global"
            >
              <div className="flex lg:flex-1 gap-6 items-center ">
                {/** Logo  */}
                <Link
                  to="/"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="-m-1.5 p-1.5"
                >
                  <span className="sr-only">Zulal</span>
                  <img className="w-28 sm:w-36" src={logo} alt="" />
                </Link>

                {/** Separater  */}
                <div className="w-[1px] h-10 bg-primary dark:bg-secondary"></div>

                {/** Contact Us : Hidden by default only visible on md (tablet screens)  */}
                <div className="hidden items-center justify-center sm:flex gap-3">
                  <div>
                    <button className="rounded-full bg-primary dark:bg-secondary w-8 h-8 xl:w-10 xl:h-10 text-textWhite hover:bg-primaryHover dark:hover:bg-secondaryHover">
                      <PhoneArrowUpRightIcon className="mx-auto w-6 h-6" />
                    </button>
                  </div>
                  <div>
                    <div className="text-sm lg:text-md 2xl:text-lg font-NatoSans hover:text-primaryHover dark:hover:text-secondaryHover">
                      Contact Us
                    </div>
                    <div className="text-md font-bold font-NatoSans lg:text-xl  hover:text-primaryHover dark:hover:text-secondaryHover ">
                      600556666
                    </div>
                  </div>
                </div>
              </div>

              {/** Main menu  */}
              <div className="hidden lg:flex lg:gap-x-6 ">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}
                    state={{ PageTitle: item.name }}
                    className="text-sm font-semibold leading-6 hover:text-primaryHover dark:hover:text-secondaryHover"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>

              {/** CTA  */}
              <div className="flex flex-1 items-center justify-end gap-x-3">
                <span className="hidden text-xl font-semibold">عربي</span>
                {/** Separater  */}
                <div className="sm:w-[1px] h-10 bg-primary dark:bg-secondary"></div>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    setMobileMenuOpen(false);
                  }}
                ></button>
                <span className="sr-only">Zulal Wallet</span>
                <Link
                  state={{ PageTitle: "STAY TUNED" }}
                  to="/ComingSoon"
                  className="flex rounded-full bg-primary h-8 w-8 xl:w-10 xl:h-10 text-textWhite items-center justify-center
                    hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-secondary"
                  aria-hidden="true"
                >
                  <UilWallet className="mx-auto w-6 h-6" />
                </Link>
                {/** User Menu */}
                <UserMenu />
                {/** Shopping Cart Button */}
                <CartIconComponent
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    setShowCart(true);
                  }}
                />
              </div>

              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="bg-primary w-8 h-8 text-textWhite  hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-secondary 
                       inline-flex items-center justify-center rounded-full -m-2.5 p-1"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            </nav>

            <CartDropDownComponent
              showCart={showCart}
              onClose={() => {
                setShowCart(false);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            />

            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-[25]" />
              <Dialog.Panel
                className="fixed inset-y-0 right-0 z-[25] myW-full overflow-y-auto bg-textwhite 
                                       px-6 py-6 sm:max-w-sm sm:myring-1 sm:ring-gray-900/10"
              >
                <div className="flex items-center gap-x-6 ">
                  <Link
                    to={"/"}
                    className="-m-1.5 p-1.5 "
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                      setMobileMenuOpen(false);
                    }}
                  >
                    <span className="sr-only">Zulal Water Company</span>
                    <img className="h-8 w-auto" src={logo} alt="" />
                  </Link>
                  {currentUser.isloggedIn === true ? (
                    <Link
                      to={"/user/logout"}
                      state={{ PageTitle: "Log Out" }}
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        setMobileMenuOpen(false);
                      }}
                      className="ml-auto bg-primary rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-textWhite shadow-sm hover:bg-indigo-500 
                  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Log Out
                    </Link>
                  ) : (
                    <Link
                      to={"/createuser"}
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        setMobileMenuOpen(false);
                      }}
                      className="ml-auto bg-primary rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-textWhite shadow-sm hover:bg-indigo-500 
                  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign up
                    </Link>
                  )}
                  <button
                    type="button"
                    className=" -m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                      setMobileMenuOpen(false);
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-10 w-10" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                      {navigation.map((item) => (
                        <Link
                          to={item.href}
                          key={item.name}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                            setMobileMenuOpen(false);
                          }}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div className="py-6">
                      {currentUser.isloggedIn ? (
                        <label className="font-medium font-poppins text-base text-navarbarText text-center">
                          Welcome{" "}
                          {!currentUser.isloggedIn
                            ? ""
                            : currentUser.outletName}
                        </label>
                      ) : (
                        <Link
                          to={"/login"}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                            setMobileMenuOpen(false);
                          }}
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Log in
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>
        </div>
      </header>
      <main>
        <Outlet />
      </main>

      <Link
        to="https://api.whatsapp.com/send/?phone=971600556666&text&type=phone_number&app_absent=0"
        target="_blank"
        rel="noopener noreferrer"
        className="w-20 h-20 bg-primary"
      >
        <UilWhatsapp className="w-16 h-16 hover:text-green-500 hover:scale-105 text-green-700 z-30 fixed bottom-10  md:left-10 left-2" />
      </Link>
    </Fragment>
  );
}
