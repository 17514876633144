import React, { useContext, useEffect, useState } from "react";
import { CallCenterAPI } from "../../utils/callcenterAPI";
import { UserContext } from "../../context/user.context";
import OrderDetails from "./OrderDetails";
import OrderDetailsInfo from "./OrderDetailsInfo";
import { UilBill, UilShoppingCart, UilSync } from "@iconscout/react-unicons";

export const Orders = () => {
  const { currentUser, sonicAccessToken } = useContext(UserContext);
  const [ordersHistory, setOrdersHistory] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);

  const customerToken = sonicAccessToken;
  //console.log(`Customer Token from Orders: ${sonicAccessToken}`);

  useEffect(() => {
    if (customerToken !== null) {
      LoadData();
    }
  }, [customerToken]);

  function getCustomerOrdersHistorylocal(params) {
    if (Object.keys(params).length !== 0) {
      const customerOrdersHistory =
        CallCenterAPI().getCustomerOrdersHistory(params);

      customerOrdersHistory
        .then((response) => {
          //console.log("We are working on order test again");

          setOrdersHistory(response);
        })
        .catch((error) => {
          console.log(error.message);
          setOrdersHistory(null);
        });
    }
  }

  function LoadData() {
    const params = { customerToken: customerToken };
    //console.log(params);
    getCustomerOrdersHistorylocal(params);
  }

  const getCustomerOrderInfo = async (orderId) => {
    if (orderId !== "" || orderId !== undefined || orderId !== null) {
      const list = ordersHistory.data.orderList;
      // const myorder = list.filter((order) => order.orderId === orderId);

      //console.log(list);

      //setOrderNumber(orderId)

      //setOrderInfo(null);

      const orderDetails = await list.filter(
        (order) => order.orderNumber === orderId
      );

      setOrderInfo(orderDetails);

      console.log(orderInfo);
    }
  };

  const showPopUpDialog = (e) => {
    //console.log(e);

    const list = ordersHistory.data.orderList;
    const orderDetails = list.filter((order) => order.orderNumber === e);

    setOrderInfo(orderDetails);

    //console.log(orderNumber);
    // if (e !== "") {
    //   getCustomerOrderInfo(e);
    // }
    setShowPopUp(true);
    //setOrderNumber(e);
  };

  // useEffect(() => {
  //   console.log(orderNumber);

  //   const list = ordersHistory.data.orderList;
  //   const orderDetails = list.filter(
  //     (order) => order.orderNumber === orderNumber
  //   );

  //   setOrderInfo(orderDetails);

  //   console.log(orderInfo);
  // }, [orderNumber]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
          Orders
          <p className="text-base text-darkTextColor font-poppins font-normal">
            Last 6 Months orders with details and status.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            className="flex rounded-md bg-secondary h-10 w-10 items-center justify-center text-center"
            onClick={LoadData}
          >
            <UilSync className="w-6 text-textWhite" />
          </button>
        </div>
      </div>

      <div className="-mx-4 mt-8 sm:-mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Order #
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Order Date
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Schedule Date
              </th>

              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Total
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Status
              </th>

              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Order Details
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {ordersHistory && ordersHistory.data !== null ? (
              ordersHistory.data.orderList.map((item) => (
                <tr key={item.orderNumber}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {item.orderNumber}
                  </td>
                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {item.orderDate}
                  </td>
                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {item.scheduleDate}
                  </td>

                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {item.netTotal.toLocaleString("en-ae", {
                      style: "currency",
                      currency: "AED",
                      minimumFractionDigits: 2,
                    })}
                  </td>
                  <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {item.status}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <button
                      id="networkInt"
                      name="btnNetworkIntAPI"
                      onClick={(e) => showPopUpDialog(item.orderNumber)}
                      className="flex bg-secondary h-8 rounded-md w-16 text-textWhite justify-center text-center items-center text-base
                      font-poppins  hover:bg-secondaryHover"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <p>No Orders to Display</p>
            )}
          </tbody>
        </table>

        <div>
          <OrderDetails
            showPopUp={showPopUp}
            orderInfo={orderInfo}
            customerAdderss={currentUser.customerAdderss}
            onClose={() => setShowPopUp(false)}
          />
        </div>
      </div>
    </div>
  );
};
