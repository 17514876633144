import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import React, { useContext, useEffect, useState } from "react";

import * as data from "../../utils/Data/GeoLocations.json";
import { UserContext } from "../../context/user.context";
import { CallCenterAPI } from "../../utils/callcenterAPI";

import generateSecretKey from "../../utils/keyGenerationUtils";
import encryptDataBase64 from "../../utils/encryptionUtils";

// country ComboBox instance
let countryObj;
// state ComboBox instance
let stateObj;
// city ComboBox instance
let cityObj;
// Area ComboBox instance
let areaObj;

//define the country ComboBox data
const tempCountry = "country";
//define the country ComboBox data
const countryData = data[tempCountry];
const countryFields = { text: "CountryName", value: "CountryID" };

//define the state ComboBox data
const tempState = "state";
//define the state ComboBox data
const stateData = data[tempState];
// State Instance

const stateFields = { text: "StateName", value: "StateID" };

//Cities Instance
const tempCity = "cities";
//define the city ComboBox data
const cityData = data[tempCity];
const citiesFields = { text: "CityName", value: "CityID" };

//Area Instance
//Cities Instance
const tempArea = "areas";
//define the city ComboBox data
const areaData = data[tempArea];
const areaFields = { text: "AreaName", value: "AreaID" };

const UserAddressUpdated = () => {
  const [countryValue, setCountryValue] = useState(1);
  const [stateValue, setStateValue] = useState(-1);
  const [cityValue, setCityValue] = useState(-1);
  const [areaValue, setAreaValue] = useState(-1);
  const [messageText, setMessageText] = useState("");
  const [groupCode, setGroupCode] = useState("");

  const env_var = process.env.NODE_ENV;

  const { currentUser, customerDetails, setCustomerDetails, sonicAccessToken } =
    useContext(UserContext);

  const [userArea, setUserArea] = useState({});

  const getCustomerDetails = async (params) => {
    customerDetails &&
      setUserArea({
        name: customerDetails.name,
        bldgName: customerDetails.bldgName,
        email: customerDetails.email,
        flatNumber: customerDetails.flatNumber,
        landmark: customerDetails.landmark,
        source: "web",
        DOB: "1990-01-01",
        postcode: "000000",
        Currency: "AED",
        GroupCode: customerDetails.splGroupCode,
        addresses: [
          {
            address: customerDetails.addresses.address,
            is_Default: true,
          },
        ],
        numbers: [
          {
            number: customerDetails.phoneNo,
            is_Default: true,
            countryId: 1,
          },
        ],
        countryID:
          customerDetails.countryID !== null ? customerDetails.countryID : -1,
        stateID:
          customerDetails.stateID !== null ? customerDetails.stateID : -1,
        cityID: customerDetails.cityID !== null ? customerDetails.cityID : -1,
        areaId: customerDetails.AreaId !== null ? customerDetails.AreaId : -1,
      });

    //console.log(customerDetails.splGroupCode);
    setGroupCode(customerDetails.splGroupCode);

    // setCountryValue(customerDetails.countryID);
    // setStateValue(customerDetails.stateID);
    // setCityValue(customerDetails.cityID);
    // setAreaValue(customerDetails.AreaId);
  };

  function countryChange() {
    // query the data source based on country ComboBox selected value
    //console.log("Country Changed");
    //console.log(countryObj.value);
    //debugger;
    if (countryObj && stateObj && cityObj) {
      //console.log("country condtion true");

      let tempQuery = new Query().where("CountryID", "equal", countryObj.value);
      stateObj.query = tempQuery;
      // clear the existing selection in state ComboBox
      stateObj.text = null;
      // bind the property change to state ComboBox
      stateObj.dataBind();
      //clear the existing selection in city ComboBox
      cityObj.text = null;
      // disable the city ComboBox
      cityObj.enabled = true;
      // bind the property change to city ComboBox
      cityObj.dataBind();
    }
  }

  function stateChange() {
    // query the data source based on state ComboBox selected value
    // console.log("State Chagned");

    if (stateObj && cityObj) {
      //console.log("state condtion true");

      //console.log("state value from State Chagned " + stateObj.value);

      let tempQuery1 = new Query().where("StateID", "equal", stateObj.value);
      cityObj.query = tempQuery1;
      //clear the existing selection
      cityObj.text = null;
      // bind the property change to city ComboBox
      cityObj.dataBind();

      areaObj.text = null;
      areaObj.enabled = true;
      areaObj.dataBind();
    }
  }

  function cityChange() {
    if (cityObj) {
      // console.log("city condition true");
      let tempQuery2 = new Query().where("CityID", "equal", cityObj.value);
      areaObj.query = tempQuery2;
    }
  }

  function loadCustomerGeoLocation() {
    const CId = customerDetails.countryID;
    const SId = customerDetails.stateID;
    const TId = customerDetails.cityID;
    let AId = customerDetails.AreaId;
    const AId2 = customerDetails.areaId;

    // console.log(CId, SId, TId, AId, AId2);

    // console.log(
    //   customerDetails.countryID,
    //   customerDetails.stateID,
    //   customerDetails.cityID,
    //   customerDetails.areaId
    // );

    setCountryValue(CId);
    let tempQuery = new Query().where("CountryID", "equal", CId);
    stateObj.query = tempQuery;

    setStateValue(SId);

    let tempQuery1 = new Query().where("StateID", "equal", SId);
    cityObj.query = tempQuery1;

    setCityValue(TId);

    let tempQuery2 = new Query().where("CityID", "equal", TId);
    areaObj.query = tempQuery2;

    if (AId === undefined) {
      AId = AId2;
    }

    setAreaValue(AId);
  }

  useEffect(() => {
    if (currentUser.customerCode !== "") {
      getCustomerDetails();
      loadCustomerGeoLocation();
    }
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserArea((userArea) => {
      return { ...userArea, [name]: value };
    });

    //console.log(userArea);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //console.log(userArea);

    const bldgName = userArea.bldgName !== "" ? userArea.bldgName : "";
    const flatNumber = userArea.flatNumber !== "" ? userArea.flatNumber : "";
    const landmark = userArea.landmark !== "" ? userArea.landmark : "";

    // console.log(
    //   countryObj.value +
    //     " " +
    //     stateObj.value +
    //     " " +
    //     cityObj.value +
    //     " " +
    //     areaObj.value
    // );
    // console.log(bldgName, flatNumber, landmark);

    //geo Location
    const CountryID = countryObj.value;
    const StateID = stateObj.value;
    const CityID = cityObj.value;
    const AreaID = areaObj.value;

    const custDetails = {
      name: currentUser.outletName,
      bldgName: bldgName,
      email: currentUser.email,
      flatNumber: flatNumber,
      landmark: landmark,
      source: "web",
      DOB: "1990-01-01",
      postcode: "000000",
      Currency: "AED",
      GroupCode: customerDetails.splGroupCode,
      addresses: [
        {
          address:
            flatNumber +
            " " +
            bldgName +
            " " +
            landmark +
            " " +
            cityObj.text +
            " " +
            areaObj.text,
          is_Default: true,
        },
      ],
      numbers: [
        {
          number: currentUser.phoneNo,
          is_Default: true,
          countryId: 1,
        },
      ],
      AreaId: areaObj.value,
    };

    if (env_var === "development") {
      console.log("Development mode enabled");
      console.log(custDetails);
    }

    //setCustomerDetails(custDetails);

    let token = sonicAccessToken;

    //console.log(token);
    //console.log(sonicAccessToken);

    if (token === null || token === undefined) {
      token = sonicAccessToken;
    }

    const secretKey = generateSecretKey();

    const encryptedCustomerInfo = encryptDataBase64(custDetails, secretKey);
    const encryptedToken = encryptDataBase64(token, secretKey);

    const encryptedData = {
      secretKey: secretKey,
      encrypteCustomerInfo: encryptedCustomerInfo,
      encryptedToken: encryptedToken,
    };

    const params = { data: encryptedData };

    //const params = { customerToken: token, userDetails: custDetails };

    const updateResponse = CallCenterAPI().updateCustomerDetails(params);

    updateResponse
      .then((res) => {
        //console.log(res);

        if (res.message === "Success") {
          setMessageText("Addreess updated successfully");
        }
      })
      .catch((err) => {
        //console.log(err.message);
        setMessageText(err.message);
      });
  };

  const changeAddress1 = (e) => {
    e.preventDefault();
    //console.log("change address 1");
    getCustomerDetails();
    loadCustomerGeoLocation();
  };

  return (
    <div className="mx-auto mr-4 ml-4 mb-28">
      <div className="text-navarbarText text-3xl font-poppins font-medium mb-8">
        User Address
        <p className="text-base text-darkTextColor font-poppins font-normal">
          Please update your current Location to serve you better
        </p>
        <br />
        <p className="text-base text-darkTextColor font-poppins font-normal">
          Currently your Address is : <br />{" "}
          {customerDetails && customerDetails.addresses[0].address}
        </p>
        <p>
          <button className="btn btn-primary" onClick={changeAddress1}>
            Change
          </button>
        </p>
      </div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        {/** Building Name  */}
        <div>
          <label
            htmlFor="bldgName"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Building Name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="bldgName"
              value={userArea.bldgName}
              onChange={handleChange}
              id="bldgName"
              autoComplete="bldgName"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
     placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/** Flat nuumber  */}
        <div>
          <label
            htmlFor="flatNumber"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Flat / Villa Number
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="flatNumber"
              value={userArea.flatNumber}
              onChange={handleChange}
              id="flatNumber"
              autoComplete="flatNumber"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/** landmark Name  */}
        <div>
          <label
            htmlFor="landmark"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Nearby Landmark
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="landmark"
              onChange={handleChange}
              value={userArea.landmark}
              id="landmark"
              autoComplete="landmark"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
             placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="countryList"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select Country
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="countryList"
              placeholder="select country"
              fields={countryFields}
              dataSource={countryData}
              value={countryValue}
              ref={(combobox) => {
                countryObj = combobox;
              }}
              change={countryChange.bind(this)}
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="cmbstate"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select State
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="cmbstate"
              dataSource={stateData}
              value={stateValue}
              ref={(combobox) => {
                stateObj = combobox;
              }}
              allowCustom={false}
              fields={stateFields}
              change={stateChange.bind(this)}
              placeholder="Select State"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="cmbCities"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select City
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="cmbCities"
              ref={(combobox) => {
                cityObj = combobox;
              }}
              value={cityValue}
              dataSource={cityData}
              allowCustom={false}
              fields={citiesFields}
              placeholder="select cities"
              change={cityChange.bind(this)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="cmbAreas"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Select Area
          </label>
          <div className="mt-2.5">
            <ComboBoxComponent
              id="cmbAreas"
              value={areaValue}
              dataSource={areaData}
              ref={(combobox) => {
                areaObj = combobox;
              }}
              allowCustom={false}
              fields={areaFields}
              placeholder="Select Area"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="RouteInfo"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Route Information
          </label>
          <div className="mt-2.5">
            <input
              name="route"
              readOnly
              id="route"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
         placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="geolocation"
            className="block text-sm font-semibold leading-6 text-gray-900"
          >
            Current Location
          </label>
          <div className="mt-2.5">
            <input
              name="geolocation"
              id="geolocation"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-secondaryHover
       placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button
          type="button"
          onClick={handleSubmit}
          className="rounded-md bg-secondary  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Update Location
        </button>
      </div>
      <span className="text-xl font-bold mt-8 ">{messageText}</span>
    </div>
  );
};

export default UserAddressUpdated;
