import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import React, { useEffect, useState, useContext } from "react";

import * as data from "../../utils/Data/GeoLocations.json";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components/navbar/Breadcrumbs";
import "./dropdown.css";

import {
  UilUser,
  UilEnvelopeAlt,
  UilLocationPinAlt,
  UilPhoneAlt,
  UilLocationPoint,
  UilMapMarker,
  UilMapPin,
  UilUserLocation,
  UilLockAccess,
} from "@iconscout/react-unicons";
import { FormInputs } from "../../components/inputs/FormInputs";

import { CallCenterAPI } from "../../utils/callcenterAPI";
import { UserContext } from "../../context/user.context";
import generateSecretKey from "../../utils/keyGenerationUtils";
import encryptDataBase64 from "../../utils/encryptionUtils";
import { SendSMSAPI } from "../../utils/smsAPI";
import Notification from "./Notification";

const CreateUser = () => {
  // country ComboBox instance
  let countryObj;
  // state ComboBox instance
  let stateObj;
  // city ComboBox instance
  let cityObj;
  // Area ComboBox instance
  let areaObj;

  //define the country ComboBox data
  const tempCountry = "country";
  //define the country ComboBox data
  const countryData = data[tempCountry];
  const [countryValue, setCountryValue] = useState(0);
  const countryFields = { text: "CountryName", value: "CountryID" };

  //define the state ComboBox data
  const tempState = "state";
  //define the state ComboBox data
  const stateData = data[tempState];
  // State Instance

  const stateFields = { text: "StateName", value: "StateID" };
  const [stateValue, setStateValue] = useState(0);

  //Cities Instance
  const tempCity = "cities";
  //define the city ComboBox data
  const cityData = data[tempCity];
  const citiesFields = { text: "CityName", value: "CityID" };
  const [citiesValue, setCitiesValue] = useState(0);

  //Area Instance
  //Cities Instance
  const tempArea = "areas";
  //define the city ComboBox data
  const areaData = data[tempArea];
  const areaFields = { text: "AreaName", value: "AreaID" };
  const [areaValue, setAreaValue] = useState(-1);
  const [areaText, setAreaText] = useState("");

  const { sonicAccessToken, setSonicAccessToken, setCurrentUser } =
    useContext(UserContext);
  const [tokenGenerated, setTokenGenerated] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [buttonLabel, setButtonLable] = useState("Get OTP"); //
  const [errorMessage, setErrorMessage] = useState("");
  const [userOTP, setUserOTP] = useState("");

  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  const input = [
    {
      id: 1,
      name: "fullname",
      type: "text",
      placeholder: "Full Name",
      pattern: "^[A-Z][a-zA-Z '.-]*[A-Za-z][^-]$",
      errorMessage: "Enter your Name",
      label: "",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email Address",
      pattern: "[a-z0-9._%+/-]+@[a-z0-9.-]+.[a-z]{2,}$",
      errorMessage: "Enter your Email Address",
      label: "",
      required: true,
    },
    {
      id: 3,
      name: "phone",
      type: "text",
      placeholder: "501234567",
      pattern: "^[0-9]{9,9}$",
      errorMessage: "Enter 9 digits of Mobile Number",
      label: "",
      required: true,
    },
    {
      id: 4,
      name: "verifyOTP",
      type: "password",
      placeholder: "Please enter your 6 Digits OTP",
      pattern: "^[0-9]{6,6}$",
      errorMessage: "Enter 6 Digits OTP",
      label: "",
      required: true,
    },
    {
      id: 5,
      name: "address",
      type: "textarea",
      placeholder: "Please enter your address",
      errorMessage: "Address is Required",
      label: "Adress",
      required: true,
    },
  ];

  const [notifyType, setNotifyType] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  const [values, setValues] = useState({
    fullname: "",
    email: "",
    phone: "",
    OTP: "",
    verifyOTP: "",
  });

  function countryChange() {
    // query the data source based on country ComboBox selected value
    //console.log("Country Change");

    let tempQuery = new Query().where("CountryID", "equal", countryObj.value);
    stateObj.query = tempQuery;

    //console.log(tempQuery);
    // clear the existing selection in state ComboBox
    stateObj.text = null;
    // bind the property change to state ComboBox
    stateObj.dataBind();
    //clear the existing selection in city ComboBox
    cityObj.text = null;
    // disable the city ComboBox
    cityObj.enabled = true;
    // bind the property change to city ComboBox
    cityObj.dataBind();

    //clear the existing selection in city ComboBox
    areaObj.text = null;
    // disable the city ComboBox
    areaObj.enabled = true;
    // bind the property change to city ComboBox
    areaObj.dataBind();
  }
  function stateChange() {
    // query the data source based on state ComboBox selected value

    let tempQuery1 = new Query().where("StateID", "equal", stateObj.value);
    cityObj.query = tempQuery1;

    //clear the existing selection
    cityObj.text = null;
    cityObj.clear();

    // bind the property change to city ComboBox
    cityObj.dataBind();

    //clear the existing selection in city ComboBox
    areaObj.text = null;
    // disable the city ComboBox
    areaObj.enabled = true;
    // bind the property change to city ComboBox
    areaObj.dataBind();
  }

  function cityChange() {
    //query the data source based on state ComboBox selected value
    let tempQuery2 = new Query().where("CityID", "equal", cityObj.value);
    areaObj.query = tempQuery2;
    //clear the existing selection
    areaObj.text = null;
    areaObj.clear();
    // bind the property change to city ComboBox
    areaObj.dataBind();
  }

  // useEffect(() => {
  //   const customerCode = { customerCode: "" };
  //   const callRes = CallCenterAPI().getSonicCustomerAccessToken(customerCode);

  //   if (sonicAccessToken === null || sonicAccessToken === undefined) {
  //     callRes
  //       .then((res1) => {
  //         if (res1.status === 0 && res1.message === "Success") {
  //           const token = res1.data.token;
  //           //console.log(token);
  //           setSonicAccessToken(token);
  //           setTokenGenerated(true);
  //         } else {
  //           console.log("Token issue");
  //           setSonicAccessToken(null);
  //           setTokenGenerated(false);
  //         }
  //       })
  //       .catch((err) => {
  //         //console.log("error: " + err);
  //         //console.log(err);
  //         console.log("Token issue");
  //         setSonicAccessToken(null);
  //         setTokenGenerated(false);
  //       });
  //   }
  // }, [sonicAccessToken]);

  useEffect(() => {
    countryObj.value = 1;
    stateObj.value = 1;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowPopUp(false);
    }, 3000);
  }, [showPopUp]);

  function generateRandomNumber() {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }

  const sendOTP = async (params) => {
    const OTP = generateRandomNumber();
    setUserOTP(OTP);

    const userInfo = {
      outletName: values.fullname,
      customerCode: "TEMP-0001",
      email: values.email,
      address: values.address,
      phoneNo: values.phone,
      OTP: OTP,
      smsType: "NewUser",
      isloggedIn: false,
    };

    //console.log(values.phone);
    if (values.phone !== "") {
      const secretKey = generateSecretKey();
      //console.log("Generated Secret Key (Frontend):", secretKey);
      const encryptedMyData = encryptDataBase64(userInfo, secretKey);
      //console.log("Encrypted Data (Frontend):", encryptedMyData);
      //setEncryptedData(encryptedMyData);

      //const decryptedData1 = decryptDataBase64(encryptedMyData, secretKey);
      //console.log("Decrypted Data (Frontend):", decryptedData1);
      //setDecryptedData(decryptedData1);

      //console.log("after decrypting");

      const data = {
        encryptedData: encryptedMyData,
        secretKey: secretKey,
        smsType: "NewUser",
      };

      const smsRes = SendSMSAPI().sendOTPByNewPro(data);
      smsRes
        .then((response) => {
          if (response.errorCode == 0 && response.responseCode == 0) {
            setShowPopUp(true);
            setErrorMessage("OTP sent successfully");
            setNotifyType("success");
            setButtonLable("Verify OTP");
            setDisabled(false);
          } else {
            setShowPopUp(true);
            setErrorMessage(response.responseMessage);
            setNotifyType("error");
          }
        })
        .catch((err) => {
          setButtonLable("GET OTP");
          setErrorMessage("SMS Error: SMS cannot be sent at this time  " + err);
          //console.log(err);
          setShowPopUp(true);
        });
    }
  };

  async function createNewUser(token) {
    const NewUser = {
      Name: values.fullname,
      email: values.email,
      source: "app",
      DOB: "1990-01-01",
      CountryId: countryObj.value,
      StateId: stateObj.value,
      CityId: cityObj.value,
      AreaId: areaObj.value,
      postcode: "000000",
      Currency: "AED",
      GroupCode: "CS",
      numbers: [
        {
          number: values.phone,
          is_Default: true,
          CountryId: 1,
        },
      ],
      addresses: [
        {
          address: address,
          is_Default: true,
        },
      ],
    };

    //console.log(address);
    const params = { data: NewUser, token: token };
    const newUserRes = CallCenterAPI().createNewcustomer(params);

    newUserRes
      .then((res) => {
        //console.log(res);
        //console.log(res.data.token);
        //console.log(res.data.customerCode);

        if (res.successCode === 200) {
          setShowPopUp(true);
          setErrorMessage("Your Account created successfully");
          setNotifyType("success");

          //sessionStorage.setItem("customerToken", res.data.token);
          setSonicAccessToken(res.data.token);

          setCurrentUser({
            customerCode: res.data.customerCode,
            outletName: NewUser.Name,
            email: NewUser.email,
            phoneNo: NewUser.numbers[0].number,
            customerAddress: NewUser.addresses[0].address,
            OTP: "",
            isloggedIn: true,
          });
          setButtonLable("Login to Account");
        } else if (res.successCode === 400) {
          setErrorMessage(res.message);
          setShowPopUp(true);
          setNotifyType("error");
        } else if (res.status == -1) {
          setErrorMessage(res.message);
          setShowPopUp(true);
          setNotifyType("error");
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.message);
        setShowPopUp(true);
        setNotifyType("error");
      });
  }

  const onChangeInputs = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    //console.log(`name: ${e.target.name}, value: ${e.target.value}`);

    if (e.target.name === "phone") {
      setButtonLable("Get OTP");
      setDisabled(true);
    }

    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = sonicAccessToken;

    if (
      countryObj.value == null ||
      stateObj.value == null ||
      cityObj.value == null ||
      areaObj.value == null
    ) {
      setErrorMessage(
        "Please select a country, State, City and Area from the dropdown"
      );
      setShowPopUp(true);
      setNotifyType("error");

      return null;
    } else {
      if (buttonLabel === "Get OTP") {
        const sendOTPRes = await sendOTP();
      } else if (buttonLabel === "Verify OTP") {
        if (userOTP == values.verifyOTP) {
          setShowPopUp(true);
          setErrorMessage(
            "OTP Verified successfully, please click to Create Account"
          );
          setNotifyType("success");
          setButtonLable("Create Account");
        } else {
          setShowPopUp(true);
          setErrorMessage("OTP not verified");
          setNotifyType("error");
        }
      } else if (buttonLabel === "Create Account") {
        createNewUser(token);
      } else if (buttonLabel === "Login to Account") {
        navigate("/user/dashboard", {
          state: { PageTitle: "Dashboard" },
        });
      }
    }
  };

  return (
    <div className="mb-40">
      <div className="bg-pagePattern bg-center">
        <div className="flex mx-auto max-w-screen-lg text-center justify-center items-center mb-9 h-32">
          <label className="text-7xl text-textWhite font-medium  ">
            New User
          </label>
        </div>
      </div>

      <div
        className="mx-auto max-w-screen-sm md:max-w-2xl lg:max-w-3xl
                     justify-start items-start 
                     text-navarbarText text-3xl font-poppins font-medium mb-8"
      >
        <p className="ml-2 mr-2">New Account</p>
        <p className="text-base ml-2 mr-2 text-darkTextColor font-poppins font-normal">
          Please enter your Basic Information with Adress to serve you better.
          <br />
          <br />
          <p className="font-semibold">
            Enter the address in the sequence of 1. Country, 2. State, 3. City,
            4. Area and then your home/delivery address.
          </p>
        </p>
      </div>

      <div className="mx-auto max-w-screen-sm md:max-w-2xl lg:max-w-3xl text-center justify-center items ">
        <div className="mx-auto max-w-sm ml-4 mr-4 mt-4 md:max-w-2xl lg:max-w-3xl justify-center items-center">
          <div className="text-left">
            <span className="text-2xl text-primary font-poppins font-semibold">
              Account Details
            </span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="grid mx-auto text-center justify-center sm:grid-cols-1 md:grid-cols-2 gap-x-4 ">
              {/* Name */}
              <div className="flex flex-row mt-2">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
                             justify-start items-center gap-4 inline-flex"
                >
                  <UilUser className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">Name</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <FormInputs
                    autofocus="true"
                    value={values[input.name]}
                    {...input[0]}
                    onChange={onChangeInputs}
                  />
                </div>
              </div>

              {/* Email */}
              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
         justify-start items-center gap-4 inline-flex"
                >
                  <UilEnvelopeAlt className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">Email</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <FormInputs
                    value={values[input.name]}
                    {...input[1]}
                    onChange={onChangeInputs}
                  />
                </div>
              </div>

              {/* Phone */}

              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
justify-start items-center gap-4 inline-flex"
                >
                  <UilPhoneAlt className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">+971</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <FormInputs
                    pattern="[0-9]{9}"
                    value={values[input.name]}
                    {...input[2]}
                    onChange={onChangeInputs}
                  />
                </div>
              </div>

              {/* OTP */}
              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
          justify-start items-center gap-4 inline-flex"
                >
                  <UilLockAccess className="w-6 text-gray-500" />
                  <div className="font-normal text-P14 w-8">OTP</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <FormInputs
                    disabled={disabled}
                    value={values[input.name]}
                    {...input[3]}
                    onChange={onChangeInputs}
                  />
                </div>
              </div>

              {/* Country */}

              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
justify-start items-center gap-4 inline-flex"
                >
                  <UilLocationPinAlt className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">Country</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <ComboBoxComponent
                    placeholder="select country"
                    fields={countryFields}
                    dataSource={countryData}
                    value={countryValue}
                    ref={(combobox) => {
                      countryObj = combobox;
                    }}
                    change={countryChange.bind(this)}
                    onChange={(e) => {
                      setErrorMessage("");
                    }}
                  />
                </div>
              </div>

              {/* State */}

              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
justify-start items-center gap-4 inline-flex"
                >
                  <UilLocationPoint className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">State</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <ComboBoxComponent
                    id="cmbState"
                    placeholder="Select State"
                    dataSource={stateData}
                    value={stateValue}
                    fields={stateFields}
                    ref={(combobox) => {
                      stateObj = combobox;
                    }}
                    change={stateChange.bind(this)}
                    onChange={(e) => {
                      setErrorMessage("");
                    }}
                  />
                </div>
              </div>

              {/* City */}

              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
justify-start items-center gap-4 inline-flex"
                >
                  <UilMapMarker className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">City</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <ComboBoxComponent
                    id="cmbCity"
                    dataSource={cityData}
                    value={citiesValue}
                    fields={citiesFields}
                    ref={(combobox) => {
                      cityObj = combobox;
                    }}
                    placeholder="Select City"
                    change={cityChange.bind(this)}
                    onChange={(e) => {
                      setErrorMessage("");
                    }}
                  />
                </div>
              </div>

              {/* Area */}

              <div className="flex flex-row mt-2 ">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
justify-start items-center gap-4 inline-flex"
                >
                  <UilMapPin className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">Area</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <ComboBoxComponent
                    id="areaList"
                    placeholder="select area"
                    value={areaValue}
                    dataSource={areaData}
                    allowCustom={false}
                    fields={areaFields}
                    ref={(combobox) => {
                      areaObj = combobox;
                    }}
                    onChange={(e) => {
                      setErrorMessage("");
                    }}
                  />
                </div>
              </div>

              {/* Address */}
              <div className="flex flex-row mt-2 md:col-span-2">
                <div
                  className="bg-darkVeryLight ring-1 ring-secondary h-10 px-4 bg-zinc-100 rounded-tl-md rounded-bl-md 
             justify-start items-center gap-4 inline-flex"
                >
                  <UilUserLocation className="text-gray-500 w-6" />
                  <div className="font-normal text-P14 w-8">Address</div>
                </div>

                <div className="mx-auto justify-center w-full">
                  <textarea
                    id="address"
                    rows="2"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      //console.log(e.target.value);
                    }}
                    class="w-full px-4 ring-1 ring-secondary text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 
                           focus:ring-1 focus:ring-primary focus:border-primaryHover
                           dark:text-white dark:placeholder-gray-400"
                    placeholder="Enter the Flat Number / Nearest landmark..."
                    required
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-screen-sm text-center justify-center items-center mt-4">
              <button
                id="buttonSubmit"
                name="buttonSubmit"
                type="submit"
                className="bg-secondary h-14 rounded-md w-80 sm:w-full text-white
            font-poppins text-base hover:bg-secondaryHover"
              >
                {buttonLabel}
              </button>

              <div>
                <Notification
                  message={errorMessage}
                  notifyType={notifyType}
                  showPopup={showPopUp}
                  onClose={(e) => setShowPopUp(false)}
                />
              </div>

              <span className="text-textRed text-base">{errorMessage}</span>

              <div className="mt-2 text-gray-500 text-read text-base">
                Already Registered with us ? &nbsp;
                <Link
                  to={"../user/dashboard"}
                  className="font-medium text-primary dark:text-blue-500 hover:underline"
                  state={{ PageTitle: "Dashboard" }}
                >
                  Login
                </Link>
              </div>
            </div>
            <div className="mb-80"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
