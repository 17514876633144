import React, { useContext } from "react";
import { CartContext } from "../../context/cart.context";
import { ProductsContext } from "../../context/products.context";

import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

function multipleOfValues(val1, val2) {
  const total = parseFloat(val1) * parseFloat(val2);
  return total.toFixed(2);
}

const CheckoutItemCard = ({ product }) => {
  const { addItemToCart, removeItemToCart, clearItemfromCart } =
    useContext(CartContext);
  const { reCountProductsQuantity, increaseQuantity, reduceQuantity } =
    useContext(ProductsContext);

  function checkItemtoRemove(product) {
    reCountProductsQuantity(product);
    clearItemfromCart(product);
  }

  const addProductToCart = () => {
    increaseQuantity(product);
    addItemToCart(product);
  };

  const removeProductToCart = () => {
    reduceQuantity(product);
    removeItemToCart(product);
  };

  return (
    <React.Fragment>
      <div className="bg-cool-gray-100 border border-cool-gray-300 rounded-md mt-2">
        <div className="flex flex-row gap-2">
          <div>
            <img
              src={product.imageSrc}
              alt={product.imageAlt}
              className="h-40 w-40 flex-none rounded-md bg-navbarBackground border border-cool-gray-300 object-contain object-center"
            />
          </div>
          <div className="flex flex-col justify-between m-1 sm:m-2 w-full">
            <div className="text-cool-gray-600 text-xl font-semibold font-poppins">
              {product.description}
            </div>
            <div className="flex justify-between">
              <div className="text-cool-gray-600 text-base font-poppins">
                {product.price} x {product.quantity}
              </div>
              <div className="text-cool-gray-600 text-base font-semibold font-poppins">
                {multipleOfValues(product.price, product.quantity)} AED
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-row gap-2 ">
                <button
                  type="button"
                  onClick={removeProductToCart}
                  className="w-7 h-6 text-sm font-medium border  border-cool-gray-200 hover:bg-cool-gray-300 hover:text-textWhite"
                >
                  <MinusIcon className="text-[12px] w-6 text-cool-gray-500" />
                </button>
                <span className="text-cool-gray-500 px-2 text-lg font-bold">
                  {product.quantity}
                </span>
                <button
                  type="button"
                  onClick={addProductToCart}
                  className="w-7 h-6 text-sm font-medium border border-cool-gray-200  hover:bg-cool-gray-300 hover:text-textWhite"
                >
                  <PlusIcon className="text-[12px] w-6 text-cool-gray-500" />
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => checkItemtoRemove(product)}
                >
                  <TrashIcon className="w-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckoutItemCard;
