import axios from "axios";
import React from "react";
import { useContext } from "react";
import { UserContext } from "../context/user.context";
import { CallCenterAPI } from "../utils/callcenterAPI";

import generateSecretKey from "../utils/keyGenerationUtils";
import encryptDataBase64 from "../utils/encryptionUtils";
import { ProductsContext } from "../context/products.context";
import ProductComponent from "./ProductComponent";

const TestCustomerAPI = () => {
  // const handleSubmit = async (e) => {};
  const { getMyProduts, products } = useContext(ProductsContext);
  const { sonicAccessToken, currentUser, setCustomerProfile } =
    useContext(UserContext);
  const responseURL = "http://localhost:5001/api/v1/updateCustomerInfo";

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const SendCustomerData = async () => {
    //const customerToken = sonicAccessToken;

    const customerInfo = {
      name: "Sheikh Waseem",
      email: "waseem.tahir@gmail.com",
      phone: "508039006",
    };

    setCustomerProfile({
      customerName: customerInfo.name,
      customerEmail: customerInfo.email,
      customerPhone: customerInfo.phone,
    });

    const customerToken =
      "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoie1wiVXNlcklkXCI6MjQ4LFwiRW1wbG95ZWVJZFwiOjI1NyxcIk9yZ2FuaXphdGlvbklkXCI6MSxcIkN1c3RvbWVySWRcIjozNzQxMyxcIk91dGxldElkXCI6MSxcIkN1c3RvbWVyQ29kZVwiOlwiMTExOTM3NDEzXCJ9IiwiZXhwIjoxNzE1MDk4MTI0LCJpc3MiOiJOM1NvbmljIiwiYXVkIjoiTXlFbXBsb3llZXMifQ.ibXFXUBfFt5s10iBv69EBMerkryawXge76jT5qg_Wo4";

    const secretKey = generateSecretKey();

    const encryptedCustomerInfo = encryptDataBase64(customerInfo, secretKey);
    const encryptedToken = encryptDataBase64(customerToken, secretKey);

    const encryptedData = {
      secretKey: secretKey,
      encrypteCustomerInfo: encryptedCustomerInfo,
      encryptedToken: encryptedToken,
    };

    const params = { data: encryptedData };

    const updateCustRes = CallCenterAPI().updateCustomerInfo(params);

    alert("Data sent successfully");
    // .post(responseURL, {}, { params: data })
    updateCustRes
      .then((res) => {
        console.log(res.data);

        const data = res.data;
        console.log(data);
        if (data.data !== null) {
          if (data.status === 0) {
            alert(data.message);
          }

          if (data.status === 400) {
            alert(data.message);
          }
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyProducts1 = async () => {
    const products = await getMyProduts();
    console.log(products);
  };

  return (
    <div>
      <div className="mt-20 mb-40">
        <button
          id="CustomerData"
          name="btnCustomerDataAPI"
          onClick={SendCustomerData}
          className="flex mt-28 mx-auto bg-secondary h-14 rounded-md w-auto text-white
      font-poppins text-base hover:bg-secondaryHover"
        >
          Send Customer Data
        </button>

        <button
          id="CustomerData"
          name="btnCustomerDataAPI"
          onClick={getMyProducts1}
          className="flex mt-28 mx-auto bg-secondary h-14 rounded-md w-auto text-white
      font-poppins text-base hover:bg-secondaryHover"
        >
          get Products
        </button>
      </div>
    </div>
  );
};

export default TestCustomerAPI;
