import CryptoJS from "crypto-js";

const encryptDataBase64 = (data, secretKey) => {
  // Encrypt data using AES
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();

  // Encode encrypted data to Base64
  const base64Encrypted = btoa(encrypted);

  return base64Encrypted;
};

export default encryptDataBase64;

// // Function to encrypt JSON data in React.js
// const encryptAndEncodeBase64 = (jsonData, secretKey) => {
//   const encrypted = CryptoJS.AES.encrypt(
//     JSON.stringify(jsonData),
//     secretKey
//   ).toString();
//   return encrypted;
// };
// export default encryptAndEncodeBase64;
